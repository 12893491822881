module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\fadi\\Dev\\N\\eqtor\\website/src/data/locales","languages":["en","de"],"defaultLanguage":"en","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"},"pages":[{"matchPath":"/tools","languages":["en"]},{"matchPath":"/resources","languages":["en"]},{"matchPath":"/app/equitysim/:id","languages":["en"]},{"matchPath":"/:lang?/blog/:post?","getLanguageFromPath":true,"excludeLanguages":["de","en"]},{"matchPath":"/:lang?/tools/:name?","getLanguageFromPath":true,"excludeLanguages":["de","en"]},{"matchPath":"/:lang?/resources/:name?","getLanguageFromPath":true,"excludeLanguages":["de","en"]},{"matchPath":"/","languages":["en"]},{"matchPath":"/harmony","languages":["en"]},{"matchPath":"/equitysim","languages":["en"]},{"matchPath":"/eduquity","languages":["de"]},{"matchPath":"/login","languages":["en"]},{"matchPath":"/message-sent","languages":["en"]},{"matchPath":"/my-pages","languages":["en"]},{"matchPath":"/order","languages":["en"]},{"matchPath":"/privacy","languages":["en"]},{"matchPath":"/terms","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.3,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":740,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T3BLWDP","routeChangeEventName":"gatsby-route-change","includeInDevelopment":false,"defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eqtor","short_name":"Eqtor","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/data/eqtor-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7954aeb2ea26e53922135a3331d15a70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
